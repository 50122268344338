/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-const-assign */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import MobileIcon from '../images/mobile_icon.png';
import { useMenuitems } from '../hooks/getMenuItems';
import { useHeaderInfo } from '../hooks/getHeaderInfo';
import { useSocialLinks } from '../hooks/getSocialLinks';
import BannerHead from './headerBanner';

function stripLink(urlPath) {
    return urlPath.split('https://werockthespectrumenid.wrtsfranchise.com/').pop();
}

function MenuItemLink(data) {
    if (data.hasChildren) {
        return (
            <a href="#">
                {data.title}
                <span className="caret"></span>
            </a>
        );
    }
    if (data.type === 'custom') {
        return (
            <a href={data.path} rel="noopener noreferrer">
                {data.title}
            </a>
        );
    }
    if (data.type === 'taxonomy') {
        return (
            <Link to={`/${stripLink(data.path)}`} target="_blank">
                {data.title}
            </Link>
        );
    }
    if (data.type === 'post_type') {
        const postTypeUrl = data.url
            .split('https://werockthespectrumenid.wrtsfranchise.com/')
            .pop();
        return <Link to={`/${postTypeUrl}`}>{data.title}</Link>;
    }
}

function HeaderMain() {
    const [popupActive, showPopup] = useState(false);
    const [menuActive, showMenu] = useState(false);

    function togglePopup() {
        showPopup(!popupActive);
    }

    function toggleMenu() {
        showMenu(!menuActive);
    }

    const menuItems = useMenuitems();

    function fakeHandleMenu(e) {
        console.log('no children');
    }

    function handleMenu(e) {
        e.target.classList.toggle('is_active');
    }

    // content
    const headerInfo = useHeaderInfo();
    const socialLinks = useSocialLinks();

    return (
        <>
            <header>
                <BannerHead />
                <nav
                    className="navbar navbar-expand-lg text-uppercase fixed-top"
                    id="mainNav"
                >
                    <div className="container display_block">
                        <Link className="navbar-brand js-scroll-trigger" to="/">
                            <Img
                                fluid={
                                    headerInfo.logo_header.localFile
                                        .childImageSharp.fluid
                                }
                            />
                        </Link>
                        <div className="headertop">
                            <h6>
                            {headerInfo.header_working_hours} 
                                {/* {` `}
                                <br />
                                Please call or check <a className='headerfblink' href="https://www.facebook.com/profile.php?id=100092091523163&mibextid=LQQJ4d" target='_blank'>Facebook</a> for daily hours!
                                {` `} */}
                                {/* *All hours are flexible depending on reservations for parties/classes, etc.* */}
                                {/* OPEN 7 DAYS A WEEK 9am-6pm | Please check <a className='headerfblink' href="https://www.facebook.com/wrtsbradenton" target='_blank'>Facebook</a> for hours as they might vary due to private events. */}
                                {` `}
                                <span className="hourssep">|</span>
                                {` `}
                                <span className="yellowtxt">
                                    CONTACT US:  <span> </span>
                                    <a
                                        href={`tel:${headerInfo.header_contact_number}`}
                                    >
                                        {headerInfo.header_contact_number}
                                    </a>
                                </span>
                            </h6>
                            <Link
                                to="/register"
                                className="wrtsbtn yellowbtn healcode-register"
                            >
                                {headerInfo.header_register_button_text}
                            </Link>

                            <div
                                className={`popup_wrapper ${
                                    popupActive ? 'is_active' : ''
                                }`}
                            />
                            <div className="headersocial soc">
                                <a
                                    className="soc-facebook"
                                    href={socialLinks.social_facebook_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                                <a
                                    className="soc-instagram"
                                    href={socialLinks.social_instagram_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                                <a className='tiktok'
                                    href="https://www.tiktok.com/@wrtsenid"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <img src="https://werockthespectrumenid.wrtsfranchise.com/wp-content/uploads/2024/02/TikTok-Icon-1.svg" alt='tiktok' />
                                </a>
                                {/* <div className="cart-div">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: socialLinks.cart_link,
                                        }}
                                    />
                                </div> */}
                           {/* <a
                        className="soc-pinterest"
                        href={socialLinks.social_pinterest_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a> 
                              <a
                        className="soc-twitter"
                        href={socialLinks.social_twitter_link}
                        target="_blank"
                        rel="noopener noreferrer"
                    />   */}
                     {/* <a
                                    className="soc-yelp"
                                    href={socialLinks.social_yelp_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                /> */}
                            </div>
                        </div>
                        <div className="headerrgt">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={toggleMenu}
                            >
                                <img src={MobileIcon} alt="mobile icon" />
                                Menu
                            </button>
                        </div>
                    </div>
                    <div
                        className={`navitems ${
                            menuActive ? 'display_block' : ''
                        }`}
                    >
                        <ul>
                            {menuItems.map(menuItem => (
                                <li
                                    className={`${
                                        menuItem.wordpress_children
                                            ? 'nav-dropdown'
                                            : ''
                                    }`}
                                    key={menuItem.wordpress_id}
                                    id={menuItem.wordpress_id}
                                    onClick={
                                        menuItem.wordpress_children
                                            ? handleMenu
                                            : fakeHandleMenu
                                    }
                                >
                                    <MenuItemLink
                                        path={menuItem.url}
                                        url={menuItem.url}
                                        title={menuItem.title}
                                        type={menuItem.type}
                                        hasChildren={
                                            menuItem.wordpress_children
                                        }
                                    />

                                    {menuItem.wordpress_children ? (
                                        <ul>
                                            {menuItem.wordpress_children.map(
                                                childrenItem => (
                                                    <li
                                                        key={
                                                            childrenItem.wordpress_id
                                                        }
                                                    >
                                                        <MenuItemLink
                                                            path={
                                                                childrenItem.url
                                                            }
                                                            title={
                                                                childrenItem.title
                                                            }
                                                            type={
                                                                childrenItem.type
                                                            }
                                                            url={
                                                                childrenItem.url
                                                            }
                                                            hasChildren={false}
                                                        />
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    ) : (
                                        ''
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>
            </header>
            <div className="headerhigh"></div>
        </>
    );
}

export default HeaderMain;
